export const SEO = {
  title: "Darshan Parbadiya - A Product Engineer",
  url: "https://darshanparbadiya.site/",
  description:
  "I am Darshan Parbadiya, a Computer Engineer passionate about creating AI-driven solutions. I specialize in building cutting-edge, innovative, and solution-oriented applications.",
    // "I'm a self-taught Full-stack developer living in hamilton,canada. I enjoy creating things that live on the internet, whether that be websites, applications, or anything in between.",
  siteName: "https://darshanparbadiya.site/",
  github: "https://github.com/DarshanParbadiya",
  avatar: "https://avatars.githubusercontent.com/u/65885245?s=400&u=1acd714edb75170766ea3924f26a23afa550cd33&v=4",
  author: "Darshan Parbadiya",
  // twitter: {
  //   handle: "@Darshan",
  //   site: "@FelixTellmann",
  //   cardType: "summary_large_image",
  // },
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://darshanparbadiya.site/",
    site_name: "https://darshanparbadiya.site/",
    title: "Darshan Parbadiya",
    description:
      "Lets make things better with quality code - Learn Web Development / API's / Automations / Serverless / Architecture / and more.",
    images: [
      {
        url: "https://avatars.githubusercontent.com/u/65885245?s=400&u=1acd714edb75170766ea3924f26a23afa550cd33&v=4",
        alt: "Darshan Parbadiya - Full-stack Engineer - Next.js, Typescript, Tailwindcss, Spring boot",
        width: 400,
        height: 400,
      },
    ],
  },
};

export const BLOG_SEO = {
  title: "Darshan Parbadiya - Blog",
  description:
    "Learn Web Development - TypeScript / React / APIs / Automations / Serverless / Architecture / and more. - Lets make things better with quality code",
  openGraph: {
    title: "Darshan Parbadiya- Blog",
    description:
      "Learn Web Development - TypeScript / React / APIs / Automations / Serverless / Architecture / and more. - Lets make things better with quality code",
  },
};
